import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CheckDomain, ResignUp, ReVerifyEmail, VerifyEmail } from '../AllModel/AllModel';
import { HttpmethodsService } from '../service/httpmethods.service';

@Component({
  selector: 'app-create-domain',
  templateUrl: './create-domain.component.html',
  styleUrls: ['./create-domain.component.css']
})
export class CreateDomainComponent implements OnInit {
  checkDomainURL: string = '/user/check-subdomain';
  signUpURL: string = '/user/signup';
  verifyEmailURL: string = '/tempuser/verifyEmail';
  resendEmailURL: string = '/tempuser/resendVerifyEmail';
  CheckDomainModel = new CheckDomain();
  ResignUpModel = new ResignUp();
  VerifyEmailModel = new VerifyEmail();
  ReVerifyEmailModel = new ReVerifyEmail();
  userEmail: string = '';
  domainform: FormGroup;
  hideDivFlag: boolean = false;
  userId: string;
  token: string;
  compName: string;
  email: string;
  source: string;
  emailFlag: boolean = true;
  congratsScreenFlag: boolean = false;
  passwordFlag: boolean = false;
  passwordIsValid = false;

  FULL_DASH_ARRAY = 283;
  RESET_DASH_ARRAY = `-57 ${this.FULL_DASH_ARRAY}`;

  //Time related vars
  TIME_LIMIT = 10; //in seconds
  timePassed = -1;
  timeLeft = this.TIME_LIMIT;
  timerInterval = null;



  constructor(private formBuilder: FormBuilder, private httpServices: HttpmethodsService, public toastr: ToastrManager, private router: Router, private route: ActivatedRoute) {

    this.route.queryParams.subscribe(params => {
      this.VerifyEmailModel.userId = params['userId'];
      this.VerifyEmailModel.token = params['token'];
      this.compName = params['company'];
      this.ReVerifyEmailModel.email = params['email'];
      this.source = params['source'];
      if (this.source == 'fbadv') {
        this.passwordFlag = true;
      }
      else {
        this.passwordFlag = false;
      }
    });
  }
  ngOnInit(): void {
    this.domainform = this.formBuilder.group({
      domain: new FormControl('', [Validators.required]),
      Password: new FormControl('', [Validators.required]),
      email: new FormControl(''),
    })
    this.VerifyEmail();

    if (this.compName) {
      let stringToSplit = this.compName;
      let x = stringToSplit.split(" ");
      this.CheckDomainModel.domainName = x[0];
    }

  }

  passwordValid(event) {
    console.log('passwordValid---', event);
    this.passwordIsValid = event;
  }

  disabledButton() {
    if(this.passwordFlag){
      return !this.passwordIsValid && !this.domainform.valid;
    }
    return !this.domainform.controls['domain'].valid;
  }


  VerifyEmail() {
    this.httpServices.postRequest(this.verifyEmailURL, this.VerifyEmailModel).subscribe(
      (data: any) => {
        console.log('verify-data-', data);
        if (data.message === 'Email verified') {
          this.toastr.successToastr(data.message);
          this.emailFlag = true;
          this.congratsScreenFlag = false;
          this.hideDivFlag = false;
        }
      },
      (error: any) => {
        let err = error.error;
        console.log('verify-error-', err);
        this.hideDivFlag = true;
        this.emailFlag = false;
        // if (err.code == 200) {
        //   this.toastr.successToastr(err.message);
        //   this.congratsScreenFlag = false;
        // }
        // else {
        //   this.emailFlag = true;
        // }
      }
    );
  }

  ResendEmail() {
    this.httpServices.postRequest(this.resendEmailURL, this.ReVerifyEmailModel).subscribe((data: any) => {
      this.toastr.successToastr(data.message)
    },
      (error: any) => {
        let err = error.error;
        if (err.code == 200) {
          this.congratsScreenFlag = false;
          this.hideDivFlag = true;
        }
      }
    )
  }

  CheckDomain() {
    this.httpServices.getRequest(this.checkDomainURL + "/" + this.CheckDomainModel.domainName).subscribe((data: any) => {
      this.toastr.infoToastr(data.message);
    },
      (error: any) => {
        let err = error.error;
        if (err.code == 400) {
          // this.toastr.infoToastr(err.message);
          this.SignUp();
        }
        else {
          // this.toastr.infoToastr(err.message); 
        }
      });
  }

  SignUp() {
    this.ResignUpModel.tempUserId = this.VerifyEmailModel.userId;
    this.ResignUpModel.name = this.CheckDomainModel.domainName;
    this.httpServices.postRequest(this.signUpURL, this.ResignUpModel).subscribe((data: any) => {
      if (data.message === "Signup Successfull") {
        this.toastr.successToastr(data.message);
        this.hideDivFlag = true;
        this.congratsScreenFlag = true;
        this.startTimer();
      }
    },
      (error: any) => {
        let err = error.error;
        if (err.code == 200) {
          // this.toastr.successToastr(err.message);
          // this.hideDivFlag = false;
          // this.congratsScreenFlag = true;
          // this.startTimer();
        }
        else {
          this.toastr.errorToastr(err.message);
        }
      }
    )
  }


  startTimer() {
    this.timerInterval = setInterval(() => {
      this.timePassed = this.timePassed += 1;
      this.timeLeft = this.TIME_LIMIT - this.timePassed;
      let timeLabel = document.getElementById("base-timer-label");
      timeLabel.innerHTML = this.formatTime(this.timeLeft);
      this.setCircleDasharray();

      if (this.timeLeft === 0) {
        this.timeIsUp();
      }

    }, 1000);
  }

  setDisabled(button) {
    button.setAttribute("disabled", "disabled");
  }

  removeDisabled(button) {
    button.removeAttribute("disabled");
  }

  timeIsUp() {
    clearInterval(this.timerInterval);
    this.GoToLogin();
  }

  formatTime(time) {
    const minutes = Math.floor(time / 60);
    let seconds: any = time % 60;

    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${minutes}:${seconds}`;
  }

  calculateTimeFraction() {
    const rawTimeFraction = this.timeLeft / this.TIME_LIMIT;
    return rawTimeFraction - (1 / this.TIME_LIMIT) * (1 - rawTimeFraction);
  }

  setCircleDasharray() {
    const circleDasharray = `${(
      this.calculateTimeFraction() * this.FULL_DASH_ARRAY
    ).toFixed(0)} 283`;
    console.log("setCircleDashArray: ", circleDasharray);
    let timerEle = document.querySelector("#base-timer-path-remaining");
    timerEle.setAttribute("stroke-dasharray", circleDasharray);
  }

  GoToLogin() {
    // this.router.navigateByUrl("https://" + this.CheckDomainModel.domainName + ".qnvert.com")
    window.location.href = "https://" + this.CheckDomainModel.domainName + ".qnvert.com";
  }

}

