import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ToastrManager } from "ng6-toastr-notifications";
import { HttpmethodsService } from "../service/httpmethods.service";

@Component({
  selector: "app-basic-details-form",
  templateUrl: "./basic-details-form.component.html",
  styleUrls: ["./basic-details-form.component.css"],
})
export class BasicDetailsFormComponent implements OnInit {
  basicDetailsForm: any;
  basicDeatailsURL = "/user/saveDetails";
  getCountryURL = "/user/getCountries";
  isMarketing = true;
  countryList:any;
  isSales = true;
  isService = false;
  userId: any;
  selectedCountryName:string;
  domainName: any;
  isDisabled = false;
  constructor(
    private formBuilder: FormBuilder,
    public httpServices: HttpmethodsService,
    public toastr: ToastrManager,
    public route: ActivatedRoute
  ) {
    this.route.params.subscribe((data) => {
      if (data["_id"]) {
        this.userId = data["_id"];
      }
    });
    this.domainName = sessionStorage.getItem("domainName");
  }

  ngOnInit() {
    this.basicDetailsForm = this.formBuilder.group({
      userName: new FormControl("", [Validators.required]),
      city: new FormControl("", ),
      phoneNo: new FormControl("", [Validators.required]),
      country: new FormControl("", ),
      conditionFlag: new FormControl(true),
   
    });
    this.getcountry();
    

  }
  onChangeMarketing() {
    this.isMarketing = !this.isMarketing;
  }
  onChangeSales() {
    this.isSales = !this.isSales;
  }
  onChangeService() {
    this.isService = !this.isService;
  }

  submit() {
    if (this.basicDetailsForm.invalid) {
      this.toastr.errorToastr("All Fields are required");
      this.basicDetailsForm.markAllAsTouched();
      return false;
    }
    if (
      this.isMarketing == false &&
      this.isSales == false &&
      this.isService == false
    ) {
      this.toastr.errorToastr("Please Choose options ");
      this.basicDetailsForm.markAllAsTouched();
      return false;
    }

    let data = this.basicDetailsForm.getRawValue();
    let qnvertModules = [];
    if (this.isMarketing) {
      qnvertModules.push("Marketing");
    }
    if (this.isSales) {
      qnvertModules.push("Sales");
      
    }
    if (this.isService) {
      qnvertModules.push("Service");
    }
    data["qnvertModules"] = qnvertModules;
    delete data.conditionFlag;
    this.isDisabled = true;
    this.httpServices
      .putRequest(this.basicDeatailsURL + "/" + this.userId, data)
      .subscribe(
        (data: any) => {
          if (data.code == 200) {
            this.isDisabled = false;
            console.log("dataaaaa",data)
            this.GoToLogin();
          }
        },
        (error: any) => {
          this.isDisabled = false;
          let err = error.error;
          this.toastr.errorToastr(err.message);
        }
      );
  }
  GoToLogin() {
    // if (this.domainName) {
    //   window.location.href = "https://" + this.domainName + ".qnvert.com";
    // }
    if (this.domainName) {
          window.location.href = "https://" + this.domainName + ".qnvert.com";
        } else {
          this.checkDomainConfig();
        }
  }



  getUser: string = "/user/subdomain-present-check";
checkDomainConfig() {

  this.httpServices
    .postRequest(this.getUser, { _id: this.userId })
    .subscribe(
      (data: any) => {
        window.location.href = "https://" + data.domainName + ".qnvert.com";
      },
      (error: any) => {
        let err = error.error;
        // this.toastr.infoToastr(err.message);
      }
    );

}




  getcountry(){
    this.httpServices.getRequest(this.getCountryURL).subscribe((data:any)=>{
      this.countryList=data.data;
      this.selectedCountryName=data.data[110];
      console.log(this.selectedCountryName)
    })
  }


}











// GoToLogin() {
//   if (this.domainName) {
//     window.location.href = "https://" + this.domainName + ".qnvert.com";
//   } else {
//     this.checkDomainConfig();
//   }
// }

// 