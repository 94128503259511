import { Component, ErrorHandler, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrManager } from "ng6-toastr-notifications";
import { SignUp } from "../AllModel/AllModel";
import { HttpmethodsService } from "../service/httpmethods.service";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"],
})
export class SignupComponent implements OnInit {
  signUpform: FormGroup;
  signUpModel = new SignUp();
  signUpURL: string = "/tempuser/signup";
  conditionFlag: Boolean = true;
  passwordIsValid = false;
  isFormSubmitted = false;
  emailExist = false;

  constructor(
    private formBuilder: FormBuilder,
    private httpServices: HttpmethodsService,
    public toastr: ToastrManager,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.signUpform = this.formBuilder.group({
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required]),
      companyName: new FormControl("", [Validators.required]),
      conditionFlag: new FormControl(""),
    });
  }

  SignUp() {
    this.isFormSubmitted = true;
    this.emailExist = false;
    if (
      this.signUpModel.company != null &&
      this.signUpModel.email != null &&
      this.signUpModel.password != null
    ) {
      if (this.passwordIsValid) {
        this.httpServices
          .postRequest(this.signUpURL, this.signUpModel)
          .subscribe(
            (data: any) => {
              sessionStorage.setItem("id", data._id);
              sessionStorage.setItem("email", data.email);
              this.toastr.successToastr("Sign Up Successful");
              this.router.navigateByUrl("/verify");
            },
            (error: any) => {
              let err = error.error;
              this.toastr.errorToastr(err.message);

              if(err.message=="Email Already Exists"){
                this.emailExist = true;
                sessionStorage.setItem("id", err._id);
                sessionStorage.setItem("email", err.email);
                this.router.navigateByUrl("/verify");
              }
             
            }
          );
      } else {
        this.toastr.errorToastr("Password is very weak");
      }
    } else {
      this.toastr.errorToastr("All Fields are required");
    }
  }

 
  passwordValid(event) {
    this.passwordIsValid = event;
  }
}
