import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import {
  MatPaginatorModule,
  MatSidenavModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatSlideToggleModule,
  MatDatepickerModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatCheckboxModule,
  MatLabel,
  MatTableModule,
  MatSlider,
  MatSliderModule,
} from "@angular/material";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CommonModule, DatePipe } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatDividerModule,
  MatListModule,
  MatToolbarModule,
  MatMenuModule,
  MatCardModule,
} from "@angular/material";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import * as $ from "jquery";
import { ToastrModule } from "ng6-toastr-notifications";
import { Ng4LoadingSpinnerModule } from "ng4-loading-spinner";
import { SignupComponent } from "./signup/signup.component";
import { VerifyYourEmailComponent } from "./verify-your-email/verify-your-email.component";
import { CreateDomainComponent } from "./create-domain/create-domain.component";
import { PasswordStrengthComponent } from "./password-strength/password-strength.component";
import { LoginComponent } from "./login/login.component";
import { Step1FormComponent } from "./stepper/step1-form/step1-form.component";
import { MainStepperComponent } from "./stepper/main-stepper/main-stepper.component";
import { Step2FormComponent } from "./stepper/step2-form/step2-form.component";
import { Step3FormComponent } from "./stepper/step3-form/step3-form.component";
import { NgOtpInputModule } from "ng-otp-input";
import { BasicDetailsFormComponent } from './basic-details-form/basic-details-form.component';
import { ForgotPassowrdComponent } from './login/forgot-passowrd/forgot-passowrd.component';
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { FreetrialComponent } from './stepper/freetrial/freetrial.component';

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    VerifyYourEmailComponent,
    CreateDomainComponent,
    PasswordStrengthComponent,
    LoginComponent,
    Step1FormComponent,
    MainStepperComponent,
    Step2FormComponent,
    Step3FormComponent,
    BasicDetailsFormComponent,
    ForgotPassowrdComponent,
    FreetrialComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxIntlTelInputModule,
    MatSliderModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatToolbarModule,
    MatMenuModule,
    MatCardModule,
    MatPaginatorModule,
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatDividerModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,

    MatSidenavModule,
    MatDividerModule,
    MatTableModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    
    MatPaginatorModule,
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    NgOtpInputModule,
  ],
  providers: [DatePipe],
  exports: [MatButtonModule, MatDialogModule, MatIconModule],
 
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [ForgotPassowrdComponent],
})
export class AppModule {}
