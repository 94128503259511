import { Component, OnInit } from '@angular/core';
import { HttpmethodsService } from 'src/app/service/httpmethods.service';

@Component({
  selector: 'app-freetrial',
  templateUrl: './freetrial.component.html',
  styleUrls: ['./freetrial.component.css']
})
export class FreetrialComponent implements OnInit {

  constructor(public httpServices: HttpmethodsService) { } 

  ngOnInit() {
  }

}
