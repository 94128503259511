import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrManager } from "ng6-toastr-notifications";
import { ReVerifyEmail } from "src/app/AllModel/AllModel";
import { HttpmethodsService } from "src/app/service/httpmethods.service";

@Component({
  selector: "app-step2-form",
  templateUrl: "./step2-form.component.html",
  styleUrls: ["./step2-form.component.css"],
})
export class Step2FormComponent implements OnInit {
  otp: string;
  OTPForm: any;
  isDisabled = false;
  _id: any;
  verifyEmailURL: string = "/tempuser/verifyEmail";
  resendEmailURL: string = "/tempuser/resendVerifyEmail";
  ReVerifyEmailModel = new ReVerifyEmail();
  constructor(
    public httpServices: HttpmethodsService,
    public toastr: ToastrManager
  ) {
    this._id = sessionStorage.getItem("id");
  }

  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  OTPconfig = {
    allowNumbersOnly: true,
    length: 5,
    disableAutoFocus: false,
  };

  ngOnInit() {
    this.OTPForm = new FormGroup({
      otp: new FormControl("", Validators.required),
    });
  }

  onOtpChange(otp) {
    this.otp = otp;
    this.OTPForm.controls.otp.setValue(this.otp);
  }

  submitOTP(event) {
    if (event.keyCode == 13) {
      this.sendOTPForm();
    }
  }

  sendOTPForm() {
    if (this.OTPForm.invalid) {
      this.toastr.errorToastr("All Fields are required");
      this.OTPForm.markAllAsTouched();
      return false;
    }
    if (this._id) {
      let OTPData = this.OTPForm.getRawValue();
      OTPData.userId = this._id;
      this.isDisabled = true;
      this.httpServices.postRequest(this.verifyEmailURL, OTPData).subscribe(
        (data: any) => {
          if (data.message == "Email verified") {
            this.isDisabled = false;
            this.toastr.successToastr(data.message);
            this.httpServices.isStepCompleted3 = true;
            this.httpServices.stepValue = 3;
          }
        },
        (error: any) => {
          this.isDisabled = false;
          let err = error.error;
          this.toastr.errorToastr(err.message);
        }
      );
    }
  }

  ResendEmail() {
    this.httpServices
      .postRequest(this.resendEmailURL, this.ReVerifyEmailModel)
      .subscribe(
        (data: any) => {
          if (data.code == 200) {
            this.toastr.successToastr(data.message);
          }
        },
        (error: any) => {
          let err = error.error;
          if (err.code == 200) {
          }
        }
      );
  }
}
