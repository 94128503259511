import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { VerifyYourEmailComponent } from "./verify-your-email/verify-your-email.component";
import { SignupComponent } from "./signup/signup.component";
import { CreateDomainComponent } from "./create-domain/create-domain.component";
import { LoginComponent } from "./login/login.component";
import { MainStepperComponent } from "./stepper/main-stepper/main-stepper.component";
import { BasicDetailsFormComponent } from "./basic-details-form/basic-details-form.component";
import { FreetrialComponent } from "./stepper/freetrial/freetrial.component";

const routes: Routes = [
  { path: "signup", component: MainStepperComponent },
  { path: "_signup", component: FreetrialComponent },

  // { path: "basicDetails", component: BasicDetailsFormComponent },
  { path: "basicDetails/:_id", component: BasicDetailsFormComponent },
  { path: "verify", component: VerifyYourEmailComponent },
  { path: "verify-email", component: CreateDomainComponent },
  { path: "login", component: LoginComponent },
  { path: "", pathMatch: "full", redirectTo: "signup" },
  {path:"**",redirectTo:"signup"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
