import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Redirect } from '../AllModel/AllModel';
import { MatDialog } from '@angular/material';
import { ForgotPassowrdComponent } from './forgot-passowrd/forgot-passowrd.component';

@Component({
  selector: 'app-create',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  createform: FormGroup;
  domain: any;
  constructor(private formBuilder: FormBuilder, public router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.createform = this.formBuilder.group({
      domain: new FormControl(''),
    })
  }

  RedirectToAnother() {
    // this.router.navigateByUrl(this.domain + ".qnvert.com");
    window.location.href = "https://" + this.domain + ".qnvert.com";
  }


  openForgetPasswordModal()  {

    const modalRef = this.dialog.open(ForgotPassowrdComponent, {
      width: "400px",
      height: "320px",
    });

    modalRef.afterClosed().subscribe((result: any) => {
if (result) {
  
//   var newTab = window.open("https://" + result.data.url, "_blank");
// newTab.focus();
// this.toastr.successToastr('We have successfully sent you an email with the link.');

}

      console.log('qwerty')
    })


  }

  







}
