import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-verify-your-email',
  templateUrl: './verify-your-email.component.html',
  styleUrls: ['./verify-your-email.component.css']
})
export class VerifyYourEmailComponent implements OnInit {

  timer: any = '';
  IsTimerOn: boolean = true;
  FULL_DASH_ARRAY = 283;
  RESET_DASH_ARRAY = `-57 ${this.FULL_DASH_ARRAY}`;

  //Time related vars
  TIME_LIMIT = 600; //in seconds
  timePassed = -1;
  timeLeft = this.TIME_LIMIT;
  timerInterval = null;
  constructor() { }

  ngOnInit(): void {
    this.start();
  }


  start(withReset = false) {

    this.startTimer();
  }

  startTimer() {
    this.timerInterval = setInterval(() => {
      this.timePassed = this.timePassed += 1;
      this.timeLeft = this.TIME_LIMIT - this.timePassed;
      let timeLabel = document.getElementById("base-timer-label");
      timeLabel.innerHTML = this.formatTime(this.timeLeft);
      this.setCircleDasharray();

      if (this.timeLeft === 0) {
        this.timeIsUp();
      }
    }, 1000);
  }

  setDisabled(button) {
    button.setAttribute("disabled", "disabled");
  }

  removeDisabled(button) {
    button.removeAttribute("disabled");
  }

  timeIsUp() {
    clearInterval(this.timerInterval);
  }

  formatTime(time) {
    const minutes = Math.floor(time / 60);
    let seconds: any = time % 60;

    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${minutes}:${seconds}`;
  }

  calculateTimeFraction() {
    const rawTimeFraction = this.timeLeft / this.TIME_LIMIT;
    return rawTimeFraction - (1 / this.TIME_LIMIT) * (1 - rawTimeFraction);
  }

  setCircleDasharray() {
    const circleDasharray = `${(
      this.calculateTimeFraction() * this.FULL_DASH_ARRAY
    ).toFixed(0)} 283`;
    console.log("setCircleDashArray: ", circleDasharray);
    let timerEle = document.querySelector("#base-timer-path-remaining");
    timerEle.setAttribute("stroke-dasharray", circleDasharray);
  }


}