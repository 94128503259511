import { Component } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
// import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'AdminDashboardUI';
  constructor(public toastr: ToastrManager){}

  // ShowSuccess(){
  //   this.toastr.success("Successfully Worked..!!");private toastr: ToastrService
  // }

 
}
