import { Component, OnInit } from "@angular/core";
import { HttpmethodsService } from "src/app/service/httpmethods.service";

@Component({
  selector: "app-main-stepper",
  templateUrl: "./main-stepper.component.html",
  styleUrls: ["./main-stepper.component.css"],
})
export class MainStepperComponent implements OnInit {
  constructor(public httpServices: HttpmethodsService) {}

  ngOnInit() {}
}
